body {
  margin: 2px;
  padding: 0;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #BFE354;
  border: 10px solid #E354BF;
}
html {
  margin: 0;
  padding: 0;
  height: 100vh;
}
