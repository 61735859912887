.Layout {
    text-align: center;
    max-width: 400px;
    margin: auto;
}
.LayoutTitle {
    width: 80%;
    padding: 20px;
    margin: 16px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    background-color: #54BFE3;
    color: #f7fbe9;
}
.ShowQuestion {
    width: 80%;
    padding: 20px;
    margin: 16px;
    border: 1px solid #eee;
    box-shadow: 0 2px 2px #ccc;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    font-size: 20px;
    background-color: #f7fbe9;
}
.ShowQuestionTitle {
    width: 80%;
    padding: 20px;
    box-sizing: border-box;
    margin: 16px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
}
.AddAnswer {
    width: 50%;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    font-size: 15px;
}
.AddAnswer input {
    width: 30%;
    font-weight: bold;
    text-align: left;
    display: inline-block;
    font-size: 15px;
}
.ShowResult {
    width: 80%;
    height: 100px;
    padding: 20px;
    margin: 16px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    font-size: 20px;
    background-color: #f7fbe9;
}
button {
    margin-left: 10px;
    text-align: center;
    font-size: 15px;
    color: #f7fbe9;
    background-color: #54BFE3;
    border-radius: 4px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    border: 1px solid #E354BF;
}
button:hover {
    margin-left: 10px;
    text-align: center;
    font-size: 15px;
    color: #f7fbe9;
    background-color: #a7def1;;
    border-radius: 4px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    border: 2px solid #E354BF;
}